import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/videosone',
      name: 'videosone',
      component: () => import('../views/VideosOne.vue')
    },
    {
      path: '/videostwo',
      name: 'videostwo',
      component: () => import('../views/VideosTwo.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/About.vue')
    },
    {
      path: '/create',
      name: 'create',
      component: () => import('../components/CreateComponent.vue')
    },
    {
      path: '/edit:id',
      name: 'edit',
      component: () => import('../components/EditComponent.vue')
    },
    {
      path: '/list',
      name: 'list',
      component: () => import('../components/ListComponent.vue')
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('../views/Blog.vue')
    }
  ]
})