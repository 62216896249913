<template>

<nav class="navbar container navbar-dark bg-dark" role="navigation" aria-label="main navigation">
  <div>
    <a href="/">
      <img src="../../assets/spg-black.png" alt="..." height="220" width="165">
    </a>
  </div>
  <div id="navbar" class="navbar-menu">
    <!-- <div class="navbar-start">
      <router-link to="/about" class="navbar-item nav-link">About</router-link>
    </div> -->
    <div class="navbar-end">
      <div class="navbar-item">
      </div>
    </div>
  </div>
</nav>

</template>
<script>
export default {
  name: 'Nav',
  methods: {
  // Log the user in
  login() {
    this.$auth.loginWithRedirect();
  },
  // Log the user out
  logout() {
    this.$auth.logout({
      returnTo: window.location.origin
    });
  }
  }
}
</script>
<style lang="scss" scoped>
 .navbar.navbar-dark.bg-dark{
   background-color: #48d1cc!important;
  //  background-color: ;
    // background-color: #f4c430!important;
    // background-color: #63ade9!important;
    // background-color: #d3d3d3!important;
    box-sizing: border-box;
    vertical-align: middle;
 }
.navbar-dark .navbar-nav .nav-link{
    color:black!important;
    text-decoration:none;
}

.nav-item {
  margin:0;
}

.nav-dropdown {
  margin:0;
  padding:0;

}

</style>